<template>
  <v-dialog
    persistent
    :value="showModal"
    :max-width="600"
    @input="onCloseModal"
  >
    <v-card
      class="group-complaint-status-change"
      v-if="subcategoryIdToDisplay && groupComplaints[subcategoryIdToDisplay]"
    >
      <v-progress-linear color="loaderColor" indeterminate v-show="isLoading" />

      <div class="header">
        <div class="header-title">{{ $t("complaints.groupStatusChange") }}</div>
        <div class="subcategory-info">
          <div class="subcategory-name">
            {{ groupComplaints[subcategoryIdToDisplay].subcategoryName }}
          </div>
          <div class="steps">
            {{
              $t("complaints.subcategoriesSteps", {
                from: currentStep + 1,
                to: (allSubcategoryIds || []).length,
              })
            }}
          </div>
        </div>
      </div>

      <div v-if="subcategoryIdToDisplay" class="content">
        <ComplaintNames
          :complaintNames="
            groupComplaints[subcategoryIdToDisplay].complaintNames
          "
        />
        <div class="details-fields">
          <div
            v-if="groupComplaints[subcategoryIdToDisplay].fields.length === 0"
            class="no-fields-message"
          >
            {{ $t("complaints.noDetailsFields") }}
          </div>
          <div
            v-for="field of groupComplaints[subcategoryIdToDisplay].fields"
            :key="field.paramId"
            class="field"
          >
            <ParameterFieldProvider
              :field="field"
              @onChange="onParamValueChange"
            />
          </div>
        </div>
      </div>

      <div class="footer">
        <div class="footer-buttons-left">
          <v-btn
            rounded
            outlined
            color="roundButton"
            class="skip-step-button"
            :disabled="isLastStep"
            @click="onSkipButtonClick"
          >
            {{ $t("complaints.groupStatusChangeSkipStep") }}
          </v-btn>
        </div>
        <div class="footer-buttons-right">
          <v-btn
            color="roundButton roundButtonText--text"
            rounded
            @click="onNextButtonClick"
          >
            {{ $t("common.confirm") }}
          </v-btn>
          <v-btn
            color="roundButton roundButtonText--text"
            rounded
            @click="onCancelButtonClick"
          >
            {{ $t("common.cancel") }}
          </v-btn>
        </div>
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
import { EmitError, EmitSuccess } from "@/eventBus.js";

import ComplaintsService from "@/services/ComplaintsService";

import ParameterFieldProvider from "./ParameterFieldProvider";
import ComplaintNames from "./GroupComplaintNamesList";

export default {
  name: "GroupComplaintStatusModalChange",
  components: {
    ComplaintNames,
    ParameterFieldProvider,
  },
  props: {
    isModalShow: { type: Boolean, default: false },
    selectedComplaints: { type: Array },
    newStateId: { type: String },
  },
  data() {
    return {
      showModal: false,
      isLoading: false,
      groupComplaints: null,
      currentStep: null,
      allSubcategoryIds: null,
    };
  },
  computed: {
    subcategoryIdToDisplay() {
      return Array.isArray(this.allSubcategoryIds) && this.currentStep != null
        ? this.allSubcategoryIds[this.currentStep]
        : null;
    },
    isLastStep() {
      return this.allSubcategoryIds
        ? this.currentStep === this.allSubcategoryIds.length - 1
        : false;
    },
  },
  watch: {
    isModalShow(newValue) {
      this.showModal = newValue;

      if (newValue) {
        this.initialData();
      }
    },
  },
  methods: {
    initialData() {
      this.isLoading = true;
      const dataToFetch = [];
      const groupSelectedComplaints = {};

      this.selectedComplaints.forEach((selectedComplaint) => {
        if (groupSelectedComplaints[selectedComplaint.categoryId]) {
          const currentSubcategory =
            groupSelectedComplaints[selectedComplaint.categoryId];

          groupSelectedComplaints[selectedComplaint.categoryId] = {
            ...currentSubcategory,
            complaintIds: [
              ...currentSubcategory.complaintIds,
              selectedComplaint.id,
            ],
            complaintNames: [
              ...currentSubcategory.complaintNames,
              `${selectedComplaint.cplCid} - ${selectedComplaint.title}`,
            ],
            fields: currentSubcategory.fields,
          };
        } else {
          dataToFetch.push({
            stateId: this.newStateId,
            subcategoryId: selectedComplaint.categoryId,
          });

          groupSelectedComplaints[selectedComplaint.categoryId] = {
            subcategoryName: selectedComplaint.category,
            complaintIds: [selectedComplaint.id],
            complaintNames: [
              `${selectedComplaint.cplCid} - ${selectedComplaint.title}`,
            ],
            fields: [],
          };
        }
      });

      ComplaintsService.getFieldsForGroupComplaintStatusChange(dataToFetch)
        .then((response) => {
          response.forEach((item) => {
            if (groupSelectedComplaints[item.subcategoryId]) {
              groupSelectedComplaints[item.subcategoryId].fields =
                item.fields.map((field) => {
                  return {
                    paramId: field.paramId,
                    paramName: field.paramName,
                    paramTypeCode: field.paramTypeCode,
                    paramValue: null,
                    dictParamId: field.dictParamId,
                    dictParamValueId: null,
                  };
                });
            }
          });

          this.currentStep = 0;
          this.allSubcategoryIds = Object.keys(groupSelectedComplaints);
          this.groupComplaints = groupSelectedComplaints;
        })
        .catch(() => {})
        .finally(() => {
          this.isLoading = false;
        });
    },
    onCloseModal() {
      this.$emit("onCloseModal");
    },
    onCancelButtonClick() {
      this.$emit("onCloseModal");
    },
    onSkipButtonClick() {
      this.currentStep = this.currentStep + 1;
    },
    onNextButtonClick() {
      const groupComplaints = this.groupComplaints[this.subcategoryIdToDisplay];

      const dataToSend = {
        cplIds: groupComplaints.complaintIds,
        cplStateId: this.newStateId,
        details: groupComplaints.fields,
      };

      this.isLoading = true;
      ComplaintsService.groupStatusChangeWithFields([dataToSend])
        .then(() => {
          this.$emit("onRefresh");
          EmitSuccess(
            this.$t("complaints.groupStatusChangeSuccess", {
              subcategoryName: groupComplaints.subcategoryName,
            })
          );

          if (this.isLastStep) {
            this.$emit("onCloseModal");
          } else {
            this.currentStep = this.currentStep + 1;
          }
        })
        .catch(() => {
          EmitError(
            this.$t("complaints.groupStatusChangeError", {
              subcategoryName: groupComplaints.subcategoryName,
            })
          );
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    onParamValueChange(newField) {
      const fields = this.groupComplaints[this.subcategoryIdToDisplay].fields;

      const modyfiedItemIndex = fields.findIndex(
        (field) => field.paramId === newField.paramId
      );

      if (modyfiedItemIndex != null) {
        fields[modyfiedItemIndex] = newField;
      }
    },
  },
};
</script>

<style scope lang="scss">
.group-complaint-status-change {
  display: flex;
  flex-direction: column;

  .header {
    padding: 40px 20px 20px 20px;

    .header-title {
      color: $text-title;
      font-size: 20px;
    }

    .subcategory-info {
      display: flex;
      flex-direction: row;
      margin-top: 20px;
      padding: 0 10px;

      .subcategory-name {
        word-break: break-all;
      }

      .steps {
        margin-left: auto;
      }
    }
  }

  .content {
    border-top: 1px solid $border-line;
    padding: 10px;
    margin: 0 20px;

    .details-fields {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      align-items: center;
      margin: 30px 0 20px 0;

      .no-fields-message {
        width: 100%;
        color: $label;
        text-align: center;
      }

      .field {
        flex: 0 0 50%;
        padding: 10px;
      }
    }
  }

  .footer {
    display: flex;
    padding: 20px;
    align-items: center;

    .footer-buttons-left {
      margin-right: auto;

      .skip-step-button {
        height: 28px;
      }
    }

    .footer-buttons-right {
      margin-left: auto;

      > button {
        margin-left: 10px;
      }
    }
  }
}
</style>
