<template>
  <!-- Tile -->
  <div
    v-if="display === displayListTypes.CARDS || isMobile"
    class="tile-wrapper"
  >
    <v-card
      :flat="!isSelected"
      :height="isMobile ? null : '205'"
      class="tile"
      :style="{ borderLeft: `solid ${color}  10px` }"
      :class="{ errorBorder: error }"
      :ripple="false"
    >
      <v-layout pa-1 justify-space-between column overflow-hidden>
        <v-card-text>
          <div class="tile-content">
            <div class="tile-content-details" @click="onClick">
              <v-layout class="header">
                <v-flex class="header-title">
                  <TooltipTruncate :attrs="'heading bold title-text'">
                    {{ title }}
                  </TooltipTruncate>
                </v-flex>
              </v-layout>

              <v-layout justify-space-between overflow-hidden>
                <v-flex xs12 class="label-value">
                  <TooltipTruncate :attrs="'bold category'">
                    <span class="value">{{ category }}</span>
                  </TooltipTruncate>
                </v-flex>
              </v-layout>

              <v-layout
                justify-space-between
                overflow-hidden
                class="tile-subcontent"
              >
                <v-flex xs6 class="label-value cpl-id">
                  <span>
                    {{ cplCid }}
                  </span>
                </v-flex>
                <v-flex xs6 class="label-value right-side">
                  <span class="label">{{ $t("common.created") }}</span>
                  <div>{{ insertDate | moment("displayDate") }}</div>
                </v-flex>
              </v-layout>

              <v-layout justify-end overflow-hidden>
                <v-flex xs7 class="label-value right-side complaint-owner">
                  <span class="label">{{ $t("complaints.owner") }}</span>
                  <TooltipTruncate>{{
                    ownerUser != null && ownerUser.trim() === "()"
                      ? "-"
                      : ownerUser
                  }}</TooltipTruncate>
                </v-flex>
              </v-layout>

              <a
                class="description-section"
                :href="`/complaints/${id}`"
                target="_blank"
                @click="onComplaintIdClick"
              >
                <div class="value complaint-id">
                  {{
                    description && description.length > 360
                      ? `${description.slice(0, 360)}...`
                      : description
                  }}
                </div>
              </a>
            </div>

            <div class="tile-content-buttons">
              <div class="priority-arrow">
                <PriorityArrow :id="priority" :name="priorityName" />
              </div>
              <div class="attachments">
                <AttachmentModal
                  v-slot="{ on }"
                  :complaintId="id"
                  :readonly="true"
                  :attachmentsCount="attachmentsCount"
                  v-if="$can('CPL_READ')"
                >
                  <IconButton v-on="on" :tooltip="$t('common.attachments')">
                    stem-attachment
                  </IconButton>
                </AttachmentModal>
              </div>
              <div v-if="isJiraStatus" class="jira-integration">
                <img class="jira-img" src="@/assets/jira.svg" alt="jira" />
                <a class="jira-id" target="_blank" :href="jiraUrl">
                  {{ jiraStatusKey }}
                </a>
              </div>

              <div class="select-field">
                <v-checkbox
                  color="fields"
                  :input-value="isSelected"
                  @click.stop.prevent="$emit('select')"
                />
              </div>
            </div>
          </div>
        </v-card-text>
      </v-layout>
    </v-card>
    <span class="error--text error-message" v-if="error">{{ error }}</span>
  </div>

  <!-- Inline -->
  <div v-else-if="display === displayListTypes.INLINE" class="inline-wrapper">
    <v-card
      :flat="!isSelected"
      :style="{ borderLeft: `solid ${color}  10px` }"
      :class="{ errorBorder: error }"
      class="inline"
      :ripple="false"
    >
      <v-card-text>
        <div class="inline-content">
          <a
            class="complaint-more"
            :href="`/complaints/${id}`"
            target="_blank"
            @click="onComplaintIdClick"
          >
            <div class="priority-arrow">
              <PriorityArrow :id="priority" :name="priorityName" />
            </div>
            <v-layout
              overflow-hidden
              align-center
              class="inline-content-details textColor--text"
              @click="onClick"
            >
              <v-flex xs3>
                <v-flex class="header-title">
                  <TooltipTruncate :attrs="'heading bold title-text'">
                    {{ title }}
                  </TooltipTruncate>
                </v-flex>
              </v-flex>
              <v-flex xs3>
                <TooltipTruncate :attrs="'subheading bold category-section'">
                  <span class="value">{{ category }}</span>
                </TooltipTruncate>
              </v-flex>
              <v-flex xs2>
                <a
                  class="pl-2 complaint-id"
                  :href="`/complaints/${id}`"
                  target="_blank"
                  @click="onComplaintIdClick"
                >
                  {{ cplCid }}
                </a>
              </v-flex>
              <v-flex xs2 ml-auto>
                <span class="label">{{ $t("common.created") }}</span>
                <div>{{ insertDate | moment("displayDate") }}</div>
              </v-flex>
              <v-flex xs2>
                <span class="label">{{ $t("complaints.owner") }}</span>
                <TooltipTruncate>{{
                  ownerUser != null && ownerUser.trim() === "()"
                    ? "-"
                    : ownerUser
                }}</TooltipTruncate>
              </v-flex>
            </v-layout>
            <div class="inline-content-buttons">
              <v-flex v-if="isJiraStatus" class="jira-integration-column">
                <a
                  class="jira-id"
                  target="_blank"
                  :href="jiraUrl"
                  @click="onJiraLinkClick"
                >
                  <img class="jira-img" src="@/assets/jira.svg" alt="jira" />
                  {{ jiraStatusKey }}
                </a>
              </v-flex>
              <div class="attachments">
                <AttachmentModal
                  v-slot="{ on }"
                  :complaintId="id"
                  :readonly="true"
                  :attachmentsCount="attachmentsCount"
                  v-if="$can('CPL_READ')"
                >
                  <IconButton v-on="on" :tooltip="$t('common.attachments')">
                    stem-attachment
                  </IconButton>
                </AttachmentModal>
              </div>

              <div class="select-field">
                <v-checkbox
                  color="fields"
                  :input-value="isSelected"
                  @click.stop.prevent="$emit('select')"
                />
              </div>
            </div>
            <div class="expand-button">
              <IconButton @click="onExpandButtonClick" v-if="isExpanded">
                fa-caret-up
              </IconButton>
              <IconButton @click="onExpandButtonClick" v-else>
                fa-caret-down
              </IconButton>
            </div>
          </a>
        </div>
        <div
          class="description-section textColor--text"
          :style="[showDescription ? {} : { display: 'none' }]"
        >
          {{ description }}
        </div>
      </v-card-text>
    </v-card>
    <span class="error--text error-message" v-if="error">{{ error }}</span>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

import PriorityArrow from "../Shared/PriorityArrow";
import DisplayListTypes from "@/models/displayListTypes.js";
import AttachmentModal from "@/components/Shared/Attachment/AttachmentModal";
import IconButton from "@/components/Shared/IconButton";

export default {
  name: "ComplaintCard",
  components: {
    AttachmentModal,
    IconButton,
    PriorityArrow,
  },
  props: {
    id: {
      type: String,
      required: true,
    },
    cplCid: {
      type: String,
    },
    priority: {
      type: Number,
      default: 10,
    },
    priorityName: {
      type: String,
    },
    insertDate: {
      type: String,
    },
    realizationDate: {
      type: String,
    },
    category: {
      type: String,
    },
    categoryId: {
      type: Number,
    },
    title: {
      type: String,
    },
    description: {
      type: String,
    },
    complainerFirstname: {
      type: String,
    },
    complainerSurname: {
      type: String,
    },
    ownerUser: {
      type: String,
    },
    isSelected: {
      type: Boolean,
      default: false,
    },
    display: {
      type: String,
      default: DisplayListTypes.CARDS,
    },
    color: {
      type: String,
      default: "grey",
    },
    showCheckbox: {
      type: Boolean,
      default: true,
    },
    showEditPen: {
      type: Boolean,
      default: true,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    isOwn: {
      type: Boolean,
      default: false,
    },
    isSlaExpiring: {
      type: Boolean,
      default: false,
    },
    isJiraStatus: {
      type: Boolean,
      default: false,
    },
    jiraStatusKey: {
      type: String,
    },
    jiraUrl: {
      type: String,
    },
    error: {
      type: String,
      default: "",
    },
    attachmentsCount: {
      type: Number,
      default: 0,
    },
    isExpanded: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      editMode: false,
      propsCopy: [],
      displayListTypes: DisplayListTypes,
      showDescription: false,
    };
  },
  created() {
    this.propsCopy = { ...this.$props };
  },
  computed: {
    ...mapGetters("settings", ["hasRestriction", "isMobile"]),
    callerName() {
      return `${this.complainerSurname || ""} ${
        this.complainerFirstname || ""
      }`;
    },
  },
  watch: {
    isExpanded(value) {
      this.showDescription = value;
    },
  },
  methods: {
    confirm() {
      this.editMode = false;
      this.$emit("edit", this.propsCopy);
    },
    cancel() {
      this.editMode = false;
      this.propsCopy = { ...this.$props };
    },
    onClick() {
      if (!this.loading && !this.editMode) this.$emit("click");
    },
    onExpandButtonClick() {
      this.$emit("expand", this.id);
    },
    onComplaintIdClick(event) {
      event.preventDefault();
    },
    onJiraLinkClick(event) {
      event.stopPropagation();
    },
  },
};
</script>

<style scoped lang="scss">
.tile {
  .tile-content {
    .tile-content-details:hover {
      .description-section {
        opacity: 1;
        top: 0;
      }
    }

    .tile-content-details {
      cursor: pointer;

      .header-title {
        display: flex;
        flex-direction: row;
        font-size: 18px;

        ::v-deep.subheading {
          margin-left: 5px;
        }

        .title-text {
          margin-right: 10px;
        }
      }

      .category .value {
        color: $category-color;
        font-size: 16px;
      }

      .tile-subcontent {
        margin: 8px 0 0;
        @media screen and (max-width: 776px) {
          justify-content: space-between !important;
        }
      }
      .cpl-id {
        color: $text-color;
        font-weight: bold;
        div {
          overflow: visible !important;
        }
      }

      .complaint-owner {
        @media screen and (max-width: 776px) {
          align-items: end !important;
        }
      }
      .countdown {
        background-color: $countdown-background;
        border-radius: 5px;
        padding: 5px;
        white-space: nowrap;
      }

      .label-value {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        align-items: center;
        margin-top: 5px;
        @media screen and (max-width: 776px) {
          flex-direction: column;
          align-items: flex-start;
        }

        &.right-side {
          flex-basis: auto;
          @media screen and (max-width: 776px) {
            flex-direction: column;
          }
        }

        .label {
          color: $label;
          margin-right: 5px;
        }
      }

      ::v-deep.category {
        margin-top: 5px;
        padding: 5px 0;

        .value {
          background: $category-background;
          border-radius: 5px;
          padding: 5px;
        }
      }

      .description-section {
        opacity: 0;
        position: absolute;
        top: 20px;
        bottom: 54px;
        right: 0;
        left: 0;
        transition: 0.5s ease;
        background-color: white;
        word-break: break-all;
        text-decoration: none; // to hide underline in link

        .value {
          padding: 5px 5px 5px 10px;
        }

        .complaint-id {
          color: $text-color;
          text-decoration: none;
        }
      }
    }

    .tile-content-buttons {
      display: flex;
      flex-direction: row;
      align-items: center;
      margin-top: 20px;
      height: 30px;

      .priority-arrow {
        margin-right: 8px;
      }

      .jira-integration {
        margin-left: 12px;
        display: flex;

        .jira-img {
          width: 24px;
        }

        .jira-id {
          color: $text-color;
          white-space: nowrap;
          margin-left: 5px;
        }
      }

      .select-field {
        margin-left: auto;
      }
    }
  }
}

.inline:hover {
  background: $card-hover;
}

.inline {
  .inline-content {
    // display: flex;
    // flex-direction: row;
    .complaint-more {
      // flex-grow: 1;
      display: flex;
      text-decoration: none;
      color: black;
      align-items: center;
    }
    .priority-arrow {
      margin-right: 10px;
      align-self: center;
    }
    .inline-content-details {
      cursor: pointer;
      margin-right: 10px;
      .header-title {
        font-size: 18px;
      }

      .category-section {
        span {
          color: $category-color;
          font-size: 16px;
        }
      }
      .complaint-id {
        color: $text-color;
        font-weight: bold;
        text-decoration: none;
      }
      .countdown {
        background-color: $countdown-background;
        border-radius: 5px;
        padding: 5px;
        white-space: nowrap;
      }

      ::v-deep.category-section {
        padding: 5px 0;

        .value {
          background: $category-background;
          border-radius: 5px;
          padding: 5px;
        }
      }

      .label {
        color: $label;
      }
    }
  }

  .inline-content-buttons {
    display: flex;
    align-self: center;
    width: 195px;
    justify-content: flex-end;

    .jira-integration-column {
      margin-right: 20px;
      align-self: center;

      .jira-id {
        display: flex;
        flex-direction: column;
        color: $text-color;
        white-space: nowrap;
      }
      .jira-img {
        width: 25px;
        margin: 0 auto;
      }
    }

    .attachments {
      width: 40px;
      margin-right: 20px;
      align-self: center;
    }
  }

  .expand-button {
    align-self: center;
  }

  .description-section {
    font-size: 15px;
    margin-top: 20px;
    margin-left: 35px;
  }
}

.tile-wrapper,
.inline-wrapper {
  box-shadow: $card-shadow;
}

.error-message {
  font-size: 13px;
}

.errorBorder {
  box-shadow: inset 0 0 0 2px $error;
}
</style>
