<template>
  <div>
    <div class="message-header">
      {{ `${$t("complaints.appliesToComplaints")}:` }}
    </div>
    <div
      v-for="(complaintName, index) in complaintNamesToDisplay"
      :key="index"
      class="complaint-names"
    >
      <div class="item">
        {{ complaintName }}
      </div>
    </div>
    <div
      v-if="enableExtendedView && displayShortened"
      class="show-more-button"
      @click="onShowMoreButtonClick"
    >
      {{ $t("common.morePaged") }}
    </div>
  </div>
</template>

<script>
export default {
  name: "ComplaintNames",
  props: {
    complaintNames: { type: Array, default: () => [] },
  },
  data() {
    return {
      displayShortened: true,
    };
  },
  computed: {
    enableExtendedView() {
      return this.complaintNames.length > 3;
    },
    complaintNamesToDisplay() {
      return this.displayShortened
        ? this.complaintNames.slice(0, 3)
        : this.complaintNames;
    },
  },
  watch: {
    complaintNames() {
      // Hack - set initial value to variable after updating component.
      this.displayShortened = true;
    },
  },
  methods: {
    onShowMoreButtonClick() {
      this.displayShortened = false;
    },
  },
};
</script>

<style scope lang="scss">
.show-more-button {
  color: $text-title;
  cursor: pointer;
}

.message-header {
  margin-bottom: 10px;
}

.complaint-names {
  display: flex;
  flex-direction: column;

  .item {
    padding: 5px 0;
  }
}
</style>
