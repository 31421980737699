<template>
  <div>
    <div v-if="isStringType">
      <BaseInput
        :name="field.paramName"
        :value="field.paramValue"
        @input="onValueChange"
      />
    </div>
    <div v-if="isDateTimeType">
      <DateTimeField
        :dateLabel="field.paramName"
        :timeLabel="field.paramName"
        :isClearable="true"
        :value="field.paramValue"
        @input="onValueChange"
      />
    </div>
    <div v-if="isDateTimeFromToType">
      <DatetimeFromToPicker
        :dateLabel="field.paramName"
        :timeLabel="field.paramName"
        :isClearable="true"
        :mode="15"
        :value="field.paramValue"
        @input="onValueChange"
      />
    </div>
    <div v-if="isDictionaryType">
      <BaseSelect
        solo
        item-text="dictValue"
        item-value="dictParamValueId"
        :items="dictionaryItems"
        :name="field.paramName"
        :loading="isLoadingItems"
        :value="field.dictParamValueId"
        @input="onDictionaryValueChange"
      />
    </div>
  </div>
</template>

<script>
import {
  P_STRING,
  P_BOOL,
  P_DATETIME,
  P_DATETIME_FROM_TO,
  P_DECIMAL,
  P_DICT,
  P_REST,
} from "@/utils/FieldTypes";

import DictionaryParamService from "@/services/DictionaryParamsService";

import DateTimeField from "@/components/Fields/DateTimeField";
import DatetimeFromToPicker from "@/components/Fields/DatetimeFromToPicker";

export default {
  name: "ParameterFieldProvider",
  components: {
    DateTimeField,
    DatetimeFromToPicker,
  },
  props: {
    field: { type: Object },
  },
  data() {
    return {
      isLoadingItems: false,
      dictionaryItems: null,
    };
  },
  created() {
    if (this.isDictionaryType) {
      this.isLoadingItems = true;
      DictionaryParamService.GetDictionaryValues(this.field.dictParamId)
        .then((response) => {
          this.dictionaryItems = response;
        })
        .catch(() => (this.dictionaryItems = []))
        .finally(() => (this.isLoadingItems = false));
    }
  },
  computed: {
    isStringType() {
      return this.field.paramTypeCode === P_STRING;
    },
    isBoolType() {
      return this.field.paramTypeCode === P_BOOL;
    },
    isDateTimeType() {
      return this.field.paramTypeCode === P_DATETIME;
    },
    isDateTimeFromToType() {
      return this.field.paramTypeCode === P_DATETIME_FROM_TO;
    },
    isDecimalType() {
      return this.field.paramTypeCode === P_DECIMAL;
    },
    isDictionaryType() {
      return this.field.paramTypeCode === P_DICT;
    },
    isRestType() {
      return this.field.paramTypeCode === P_REST;
    },
  },
  methods: {
    onValueChange(newValue) {
      const editedField = {
        ...this.field,
        paramValue: newValue,
      };

      this.$emit("onChange", editedField);
    },
    onDictionaryValueChange(newValue) {
      const editedField = {
        ...this.field,
        dictParamValueId: newValue,
      };

      this.$emit("onChange", editedField);
    },
  },
};
</script>
